import { useMapPromise } from '@gmap-vue/v3/composables'
import { capitalize, ref } from 'vue'
import type { MapLayer } from '@gmap-vue/v3/components'
import { formatDateTime } from '@/utils/date.utils'
import { formatAddress } from '@/utils/address.utils'
import { TypeEnum } from '@apiTypes'
import type { TransportUpdate, Waypoint } from '@apiTypes'
import { formatRemainingDistance, formatStatus, getIcon } from '@/utils/waypoint.utils'

export function useGoogleMaps(): {
  mapRef: Ref<typeof MapLayer | null>
  decodedPath: Ref<google.maps.LatLng[]>
  decodePath: (polyline: string) => google.maps.LatLng[]
  reverseGeocode: (lat: number, lng: number) => Promise<string>
  updateMapBounds: (path: google.maps.LatLng[]) => void
  getMarkerContent: (waypoint: Waypoint) => HTMLElement
  getMarkerPosition: (coordinates: number[] | undefined) => google.maps.LatLngLiteral | undefined
  getLocationUpdateMarkerPromise: (location: TransportUpdate, fullDistance: number) => Promise<google.maps.marker.AdvancedMarkerElement | undefined>
  getKey: (waypoint: Waypoint) => string
  getMarkerPromise: (waypoint: Waypoint, index: number) => Promise<google.maps.marker.AdvancedMarkerElement | undefined> } {
  const mapRef = ref<typeof MapLayer | null>(null)
  const decodedPath = ref<google.maps.LatLng[]>([])

  function decodePath(polyline: string): google.maps.LatLng[] {
    return google.maps.geometry.encoding.decodePath(polyline)
  }

  function updateMapBounds(path: google.maps.LatLng[]) {
    const bounds = new google.maps.LatLngBounds()

    path.forEach(coord => bounds.extend(coord))
    if (mapRef.value)
      mapRef.value.fitBounds(bounds)
  }

  function toggleHighlight(markerView: google.maps.marker.AdvancedMarkerElement, map) {
    if (markerView.content.classList.contains('highlight')) {
      markerView.content.classList.remove('highlight')
      markerView.zIndex = null

      return
    }

    markerView.content.classList.add('highlight')
    markerView.zIndex = 1
    map.panTo(markerView.position)
  }

  async function getLocationUpdateMarkerPromise(
    location: TransportUpdate,
    fullDistance: number,
  ): Promise<google.maps.marker.AdvancedMarkerElement | undefined> {
    const mapPromise = useMapPromise()
    const map = await mapPromise
    const title = formatStatus(location.status)
    const position = getMarkerPosition(location.point?.coordinates)
    const historyEventElement = document.getElementById(`${location.status}-${location.updated_at}`)
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker') as google.maps.MarkerLibrary

    const marker = new AdvancedMarkerElement({
      map,
      position,
      title,
      content: getTransportUpdateMarkerContent(location, historyEventElement, fullDistance),
      gmpClickable: true,
    })

    marker.addListener('click', () => {
      toggleHighlight(marker, map)
      historyEventElement.classList.add('highlight-event')
    })

    return marker
  }

  function getTransportUpdateMarkerContent(option: TransportUpdate, historyEventElement, fullDistance: number): HTMLElement {
    const pointIcon = getIcon(option.status)
    const markerId = `marker-${option.status}-${option.created_at}`
    const existingMarker = document.getElementById(markerId)
    if (existingMarker)
      existingMarker.remove()

    const markerElement = document.createElement('div')

    markerElement.classList.add('waypoint', 'marker-location-update')
    markerElement.id = markerId

    markerElement.addEventListener('mouseleave', () => {
      setTimeout(
        () => historyEventElement.classList.remove('highlight-event'),
        500,
      )
    })
    markerElement.addEventListener('mouseover', () => {
      historyEventElement.classList.add('highlight-event')
    })

    markerElement.innerHTML = generateTransportUpdateMarkerHTML(pointIcon, option, fullDistance)

    return markerElement
  }

  function generateTransportUpdateMarkerHTML(pointIcon, option, fullDistance) {
    return `
      <div class="icon p-1">
        <img src="${pointIcon}" alt="${option.status}">
      </div>
      <div class="details">
        <div class="fw-bolder">
          ${formatStatus(option.status)}
        </div>
        <div>
          ${formatDateTime(option.updated_at)}
        </div>
        <div class="text-muted">
          ${formatRemainingDistance(option.distance)}
        </div>
      </div>
    `
  }

  async function getMarkerPromise(
    waypoint: Waypoint,
    index: number,
  ): Promise<google.maps.marker.AdvancedMarkerElement | undefined> {
    const mapPromise = useMapPromise()
    const map = await mapPromise
    const title = index === 0 ? 'Pickup' : 'Delivery'
    const position = getMarkerPosition(waypoint.point?.coordinates)
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker') as google.maps.MarkerLibrary

    const marker = new AdvancedMarkerElement({
      map,
      position,
      title,
      content: getMarkerContent(waypoint),
      gmpClickable: true,
    })

    marker.addListener('click', () => {
      toggleHighlight(marker, map)
    })

    return marker
  }

  function getMarkerContent(waypoint: Waypoint): HTMLElement {
    const pointIcon = waypoint.type === TypeEnum.Delivery ? 'fa-circle-arrow-up' : 'fa-circle-arrow-down'
    const markerId = `marker-${waypoint.type}`
    const existingMarker = document.getElementById(markerId)
    if (existingMarker)
      existingMarker.remove()

    const markerElement = document.createElement('div')

    markerElement.classList.add('waypoint', markerId)
    markerElement.id = markerId

    markerElement.innerHTML = generateMarkerHTML(pointIcon, waypoint)

    return markerElement
  }

  function generateMarkerHTML(pointIcon, waypoint) {
    return `
      <div class="icon">
        <i class="fa-solid ${pointIcon}"></i>
      </div>
      <div class="details">
        <div class="fw-bolder">
          ${waypoint.name}
        </div>
        <div>
          ${formatAddress(waypoint)}
        </div>
        <div class="text-muted">
          ${capitalize(waypoint.type)}
        </div>
      </div>
    `
  }

  function getMarkerPosition(coordinates: number[] | undefined): google.maps.LatLngLiteral | undefined {
    if (!coordinates || coordinates.length !== 2)
      return undefined
    const [lng, lat] = coordinates

    return { lng, lat }
  }

  function getKey(waypoint: Waypoint): string {
    return `${waypoint.id}_${waypoint.name}_${waypoint.city}`
  }

  async function reverseGeocode(lat: number, lng: number): Promise<string | null> {
    function includesPostCode(element: google.maps.GeocoderResult) {
      return element.types.includes('postal_code')
    }

    const latLng = {
      lat,
      lng,
    }

    const geocoder = new google.maps.Geocoder()

    try {
      const response = await geocoder.geocode({ location: latLng })

      if (!response || !response.results || response.results.length === 0)
        return ''

      for (const result of response.results) {
        const postalCodeComponent = result.address_components.find(includesPostCode)
        if (postalCodeComponent)
          return postalCodeComponent.short_name
      }

      return ''
    }
    catch (error) {
      return ''
    }
  }

  return { mapRef, decodedPath, decodePath, updateMapBounds, getMarkerPromise, getMarkerContent, getMarkerPosition, getKey, reverseGeocode, getLocationUpdateMarkerPromise }
}
