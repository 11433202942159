export function formatDateTime(dateTimeStr: string | number | Date): string {
  const optionsDate = { weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric' }
  const optionsTime = { hour: '2-digit', minute: '2-digit' }

  const date = new Date(dateTimeStr)
  const formattedDate = date.toLocaleDateString('en-UK', optionsDate as Intl.DateTimeFormatOptions)
  const formattedTime = date.toLocaleTimeString('en-UK', optionsTime as Intl.DateTimeFormatOptions)

  return `${formattedDate} – ${formattedTime}`
}

export function formatCountdownDuration(elapsedTime: number, duration: number): string {
  let seconds = Math.max(0, Math.ceil((duration - elapsedTime) / 1000))
  const hours = Math.floor(seconds / 60 / 60)

  seconds -= hours * 60 * 60

  const minutes = Math.floor(seconds / 60)

  seconds -= minutes * 60

  const minutesAndSeconds = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`

  return hours > 0 ? `${hours.toString().padStart(2, '0')}:${minutesAndSeconds}` : minutesAndSeconds
}

export function calculateDuration(startDatetimeStr: string | number | Date, endDatetimeStr: string | number | Date) {
  const startDate = new Date(startDatetimeStr)
  const endDate = new Date(endDatetimeStr)

  return (endDate.getTime() - startDate.getTime()) / 1000
}
